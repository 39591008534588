// GOLDEN PATH CODE
// This is a golden path example of multiple files used in translation
// Based on https://react.i18next.com/guides/multiple-translation-files

import React, { useState } from 'react';
import { AppLayout, BreadcrumbGroup } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFeatures, featureIsEnabled } from '../../../hooks/features';
import { AttendanceWizard } from '../wizard/attendanceWizard';
import { AttendanceModal } from '../leaveModal';

import { AttendanceReview } from './attendanceReview';
import { AttendeeTracker } from './attendeeTracker';

export function CsvAttendancePage() {
  // GOLDEN PATH NOTE
  // Translation should be split up into separate files for separate responsibilities whenever possible
  // THis keeps our translation files manageably small, and also allows us to prevent accidental overlap of
  // translation names
  // When using translation files in this way, import the most deeply nested translation namespace first
  // This makes it the "default" namespace for the file.  Then import the parent namespaces in reverse order,
  // including "translation", which is the common namespace for all files
  const { t } = useTranslation(['attendance', 'translation']);

  const navigate = useNavigate();
  const features = useFeatures();
  const [searchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [navigateTo, setNavigateTo] = useState('#');

  const hasChanges = true;
  const onNavigateAway = (href: string) => {
    if (hasChanges) {
      setModalVisible(true);
      setNavigateTo(href);
    } else {
      navigate(href);
    }
  };
  const leave = () => navigate(navigateTo);

  return (
    <AppLayout
      footerSelector=".footer"
      navigationHide={true}
      toolsHide={true}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            // GOLDEN PATH NOTE
            // Because this breadcrumb redirects to the home page, we use the default namespace for translation
            { text: t('breadcrumbs.home', { ns: 'translation' }), href: '/' },

            // GOLDEN PATH NOTE
            // This breadcrumb is based in the "attendance" namespace, which is the default for the file, so we do not need
            // to specify a namespace
            { text: t('breadcrumbs.trackAttendance'), href: '#' },
          ]}
          onFollow={(event) => {
            onNavigateAway(event.detail.href);
            event.preventDefault();
          }}
        />
      }
      content={
        featureIsEnabled('CSVAttendance', features, searchParams) ? (
          <>
            <AttendanceWizard 
              steps={[
                {
                  title: t('attendanceWizard.stepTitles.trackAttendees'),
                  content: <AttendeeTracker />,
                },
                {
                  title: t('attendanceWizard.stepTitles.viewAttendance'),
                  content: <AttendanceReview />,
                },
              ]}
              onCancel={() => onNavigateAway('/my-activity')} />
            <AttendanceModal
              hasChanges={false}
              onConfirm={leave}
              visible={modalVisible}
              onDismiss={() => setModalVisible(false)}
            />
          </>
        ) : (
          t('csvAttendanceDisabled')
        )
      }
      contentType="wizard"
    />
  );
}
