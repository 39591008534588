// GOLDEN PATH CODE
// This is a table backed by data entirely retrieved from react-query.
// Based on https://cloudscape.aws.dev/examples/react/server-side-table.html
import React, { useState } from 'react';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import CopyToClipboard from '@amzn/awsui-components-react/polaris/copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useTableStrings } from '../../../hooks/localization/tableStrings';
import { usePaginationStrings } from '../../../hooks/localization/paginationStrings';
import { useAttendeeSearchParams } from '../../../hooks/attendance/attendeeSearchParams';
import { useTableColumns } from '../../../hooks/tableColumns';
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import { usePaginatedRedVelvetQuery } from '../../../hooks/paginatedRedVelvetQuery';
import {
  Amazonian,
  columnMap,
  getAliasesFromSelected,
  getAmazoniansFromPeople,
  PEOPLE_PAGE_SIZE,
  convertTokensToPeopleQuery,
} from '../../../utils/attendance/peopleSearch';
import { publishKatalMetric } from '../../katalAnalytics';
import { PeoplePropertyFilter } from './peoplePropertyFilter';

export type AttendeeTableProps = { isImmutable?: boolean } & Partial<TableProps>;

export function AttendeeTable(props: AttendeeTableProps) {
  const namespaces = ['attendance', 'translation'];
  const { t } = useTranslation(namespaces);

  const tableStrings = useTableStrings('attendeeTable', (item: Amazonian) => item.name, namespaces);
  const paginationStrings = usePaginationStrings('attendeeTable', namespaces);
  const columns = useTableColumns(columnMap, 'attendeeTable', namespaces);

  // GOLDEN PATH NOTE
  // Table controls such as selectedItems, pagination, and filters should be controlled by state if it is unlikely
  // that the user cares to replicate them across sessions
  const [selectedItems, setSelectedItems] = useState<Amazonian[]>([]);
  const [filterObj, setFilterObj] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: 'or',
  });

  // GOLDEN PATH NOTE
  // All data that is displayed in the table is pulled from the url params (saved alias list)
  // or from the GetPeople endpoint in the API, so we should use hooks to pull that data in
  // to prevent cluttering up the display component with data logic
  // This also makes testing this comonent easier, as we can simply mock out the hooks
  const { aliases, removeAttendees } = useAttendeeSearchParams();
  const searchQuery = { ...convertTokensToPeopleQuery(filterObj), aliases: aliases.join(',') };
  const { pageIndex, setPageIndex, queryResult } = usePaginatedRedVelvetQuery({
    redVelvetQueryKey: ['getPeople', searchQuery],
  });
  const people = getAmazoniansFromPeople(queryResult.data?.people);

  return (
    <Table
      {...props}
      header={
        <Header
          actions={
            props.isImmutable ? null : (
              <SpaceBetween direction="horizontal" size="xs">
                <CopyToClipboard
                  copyButtonText={t('attendeeTable.copyAttendees')}
                  copyErrorText={t('attendeeTable.copyAttendeesFailed')}
                  copySuccessText={t('attendeeTable.copyAttendeesSuccess')}
                  data-aci-analytics-name="attendance_copyAliasList"
                  textToCopy={
                    selectedItems.length > 0
                      ? getAliasesFromSelected(selectedItems).join(',')
                      : aliases.join(',')
                  }
                />
                <Button
                  variant="primary"
                  disabled={selectedItems.length === 0}
                  onClick={() => {
                    setSelectedItems([]);
                    const aliases = getAliasesFromSelected(selectedItems);
                    removeAttendees(aliases);
                    publishKatalMetric('attendance_removeAttendees', aliases.join(','));
                  }}
                >
                  {t('attendeeTable.removeAttendees')}
                </Button>
              </SpaceBetween>
            )
          }
          counter={
            selectedItems.length
              ? `(${selectedItems.length}/${aliases.length})`
              : `(${aliases.length})`
          }
        >
          {t('attendeeTable.title')}
        </Header>
      }
      filter={
        <PeoplePropertyFilter
          query={filterObj}
          onChange={({ detail }) => {
            const metricValue = detail.tokens
              .map((token) => `${token.propertyKey || 'fuzzySearch'}:${token.value}`)
              .join(',');
            publishKatalMetric('attendance_attendeePeopleFilter', metricValue);
            setSelectedItems([]);
            setPageIndex(1);
            setFilterObj(detail);
          }}
        />
      }
      pagination={
        <Pagination
          currentPageIndex={pageIndex}
          ariaLabels={paginationStrings}
          // We know the exact number of pages based on the saved data in the url query params, so we should use it here
          pagesCount={Math.ceil(aliases.length / PEOPLE_PAGE_SIZE)}
          onChange={({ detail }) => setPageIndex(detail.currentPageIndex)}
        />
      }
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
      selectionType={props.isImmutable ? undefined : 'multi'}
      {...tableStrings}
      {...columns}
      empty={
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <b>
            {queryResult.isError ? t('attendeeTable.errorOccurred') : t('attendeeTable.noneFound')}
          </b>
        </Box>
      }
      loading={queryResult.isLoading}
      items={people || []}
      trackBy="name"
      data-testid={'attendee-table'}
    />
  );
}
