import React, { useCallback, useMemo, useState } from 'react';
import {
  Header,
  Pagination,
  PropertyFilterProps,
  StatusIndicator,
  Table,
} from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { useTableStrings } from '../../../hooks/localization/tableStrings';
import { usePaginationStrings } from '../../../hooks/localization/paginationStrings';
import { EditableColConfig, useTableColumns } from '../../../hooks/tableColumns';
import { FilterProperties, TablePropertyFilter } from '../wizard/tablePropertyFilter';
import {
  PEOPLE_PAGE_SIZE,
  attendanceColumnMap,
  ParticipationStatus,
  ParticipationWithFullname
} from '../../../utils/attendance/peopleSearch';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { usePropertyFilterStrings } from '../../../hooks/localization/propertyFilterStrings';
import { useUrlPage } from '../../../hooks/attendance/urlPage';
import { TimeModal } from '../../shifts/timeModal';
import { TEST_SHIFT } from '../../../__test__/data';
import { AttendanceSelect } from './attendanceSelect';
import { HeaderActions } from './attendanceTableActions';

const NAMESPACES = ['attendance', 'translation'];

const coordinatorTrackingProperties = [
  { name: 'volunteerName', operators: [':'] },
  { name: 'isSignedUp' },
  { name: 'volunteerBuildingName' },
  { name: 'volunteerManagerAlias' },
  { name: 'attendanceUpdatedByAlias' },
  { name: 'attendanceMinutes' },
  {
    name: 'level',
    values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12', '99'],
  },
  { name: 'volunteerAlias', group: 'mustMatch' },
];

interface CoordinatorAttendeeTableProps {
  participants: ParticipationWithFullname[];
  setParticipants: React.Dispatch<React.SetStateAction<ParticipationWithFullname[]>>;
}

export function CoordinatorAttendeeTable(props: CoordinatorAttendeeTableProps) {
  const { participants } = props;

  // Hooks and translations
  const { t } = useTranslation(NAMESPACES);
  const tableStrings = useTableStrings(
    'coordinatorAttendeeTable',
    (item: ParticipationWithFullname) => `${item.volunteerName}`,
    NAMESPACES
  );
  const paginationStrings = usePaginationStrings('coordinatorAttendeeTable', NAMESPACES);
  const propertyFilterStrings = usePropertyFilterStrings('propertyFilter', NAMESPACES);

  // State
  const [selectedItems, setSelectedItems] = useState<ParticipationWithFullname[]>([]);
  const [filterObj, setFilterObj] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: 'or',
  });
  const { page } = useUrlPage(0);

  // GOLDEN PATH NOTE
  // useCollection is CloudScape best practice for client-side table operations for when data can be fetched in its entirety.
  const { items, collectionProps, paginationProps, propertyFilterProps } = useCollection(
    participants,
    {
      propertyFiltering: {
        filteringProperties: FilterProperties(coordinatorTrackingProperties),
      },
      pagination: { pageSize: PEOPLE_PAGE_SIZE },
    }
  );

  const isAttendanceTrackedByVolunteer = (item: ParticipationWithFullname) => {
    return item.attendanceUpdatedByAlias === item.volunteerAlias;
  };

  // Table configuration
  const editableCols = useMemo<Record<string, EditableColConfig<ParticipationWithFullname>>>(
    () =>
      page === 0 // Step 1: Edit Attended / Did not attend status
        ? {
            attendanceStatus: {
              errorIconAriaLabel: 'State Validation Error',
              editingCell: (item, { currentValue, setValue }) => (
                <AttendanceSelect
                  participant={item}
                  currentValue={currentValue}
                  onAttendanceChange={(status: ParticipationStatus) => {
                    // TODO: Add inline editing for single attendance https://taskei.amazon.dev/tasks/cce-4506
                    setValue(status);
                  }}
                />
              ),
              disabledReason: (item) => {
                if (isAttendanceTrackedByVolunteer(item)) {
                  return 'Attendance already tracked by attendee';
                }
              },
            },
          }
        : // Step 2: Edit minutes for each attended volunteer
          {
            attendanceMinutes: {
              errorIconAriaLabel: 'State Validation Error',
              editingCell: () => (
                <TimeModal
                  shift={TEST_SHIFT}
                  visible={true}
                  onDismiss={() => {
                    // TODO: Add inline editing hours for single attendance https://taskei.amazon.dev/tasks/cce-4510
                  }}
                />
              ),
            },
          },
    [page]
  );

  const attendanceStatusCol = (item: ParticipationWithFullname): JSX.Element => {
    if (item.isSignedUp)
      switch (item.attendanceStatus) {
        case 'ATTENDED':
          return (
            <StatusIndicator type={'success'}>
              {t('coordinatorAttendeeTable.coordinatorTracking.setAttended')}
            </StatusIndicator>
          );
        case 'DID_NOT_ATTEND':
          return (
            <StatusIndicator type={'error'}>
              {t('coordinatorAttendeeTable.coordinatorTracking.setDidNotAttend')}
            </StatusIndicator>
          );
        case 'PENDING':
          return (
            <StatusIndicator type={'pending'}>
              {t('coordinatorAttendeeTable.coordinatorTracking.pending')}
            </StatusIndicator>
          );
        default:
          return (
            <StatusIndicator type={'pending'}>
              {t('coordinatorAttendeeTable.coordinatorTracking.pending')}
            </StatusIndicator>
          );
      }

    return (
      <StatusIndicator type={item.attendanceMinutes !== undefined ? 'success' : 'error'}>
        {item.attendanceMinutes !== undefined
          ? t('coordinatorAttendeeTable.coordinatorTracking.setAttended')
          : t('coordinatorAttendeeTable.coordinatorTracking.setDidNotAttend')}
      </StatusIndicator>
    );
  };

  const attendanceMinutesCol = (item: ParticipationWithFullname) => (
    <StatusIndicator type={'success'}>
      {item.attendanceMinutes
        ? `${item.attendanceMinutes} minutes`
        : t('attendeeTable.coordinatorTracking.setAttended')}
    </StatusIndicator>
  );

  const attendanceColumnsByStepMap =
    page === 0
      ? {
          // Step 1: Only show Attended/Did not attend status column
          ...attendanceColumnMap,
          attendanceStatus: attendanceStatusCol,
        }
      : {
          // Step 2: Only show Attended/xyz minutes column
          ...attendanceColumnMap,
          attendanceMinutes: attendanceMinutesCol,
        };

  const columns = useTableColumns(
    attendanceColumnsByStepMap,
    'coordinatorAttendeeTable',
    NAMESPACES,
    editableCols
  );

  const submitEdit = useCallback(async () => {
    // TODO: To call bulkCreate/bulkUpdateAttendance API
    await new Promise((resolve) => setTimeout(resolve, 10));
  }, []);

  return (
    <Table
      {...collectionProps}
      submitEdit={submitEdit}
      header={
        <Header
          actions={
            <HeaderActions
              onBulkUpdate={(status: ParticipationStatus) => {
                // TODO: Add bulk editing actions for all selected attendance https://taskei.amazon.dev/tasks/cce-4507
                return status;
              }}
            />
          }
          counter={
            selectedItems && selectedItems?.length > 0
              ? `(${selectedItems.length}/${items.length})`
              : `(${items.length})`
          }
        >
          {t('attendeeTable.title')}
        </Header>
      }
      isItemDisabled={isAttendanceTrackedByVolunteer}
      filter={
        <TablePropertyFilter
          propertyFilterStrings={propertyFilterStrings}
          i18nStrings={propertyFilterStrings}
          query={filterObj}
          onChange={(event) => {
            propertyFilterProps.onChange(event);
            setFilterObj(event.detail);
          }}
          filteringOptions={coordinatorTrackingProperties.flatMap((property) =>
            (property.values || []).map((value) => ({ propertyKey: property.name, value }))
          )}
          filteringProperties={FilterProperties(coordinatorTrackingProperties)}
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationStrings} />}
      selectionType={'multi'}
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
      {...tableStrings}
      {...columns}
      items={items || []}
      trackBy="volunteerName"
      data-testid={'coordinator-attendee-table'}
    />
  );
}
