// GOLDEN PATH CODE
// This is an example of a PropertyFilter that does not control it's own contents.
// This is useful for filters that are re-used in multiple places, and whose contents are passed to several different hooks
// Based on https://cloudscape.aws.dev/components/property-filter
import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';


// GOLDEN PATH NOTE
// This allows parent components to control the values displayed in the filter,
// and exposes the filter values to be used in other components or hooks
// It also makes all the PropertyFilterProps optional fields, since required fields
// are all provided in the reuseable definition
export interface FilterProperty {
  name: string;
  operators?: string[];
  values?: string[] | undefined;
  group?: string | undefined;
}

export type TablePropertyFilterProps = {
  query: PropertyFilterProps.Query;
  propertyFilterStrings: PropertyFilterProps.I18nStrings;
  filteringOptions: PropertyFilterProps.FilteringOption[];
  filteringProperties: PropertyFilterProps.FilteringProperty[];
  onChange: NonNullable<PropertyFilterProps['onChange']>;
} & Partial<PropertyFilterProps>;

export function FilterProperties(
  properties: FilterProperty[]
): PropertyFilterProps.FilteringProperty[] {
  const { t } = useTranslation(['attendance', 'translation']);

  return properties.map((property) => ({
    key: property.name,
    propertyLabel: t('propertyFilter.properties.' + property.name),
    groupValuesLabel: t('propertyFilter.values.' + property.name),
    operators: property.operators,
    defaultOperator: property.operators ? property.operators[0] : undefined,
    group: property.group ? t('propertyFilter.' + property.group) : undefined,
  }));
}

// GOLDEN PATH NOTE
// This allows parent components to control the values displayed in the filter,
// and exposes the filter values to be used in other components or hooks
// It also makes all the PropertyFilterProps optional fields, since required fields
// are all provided in the reuseable definition

export function TablePropertyFilter(props: TablePropertyFilterProps) {
  const namespaces = ['attendance', 'translation'];
  const { t } = useTranslation(namespaces);

  return (
    <PropertyFilter
      // GOLDEN PATH NOTE
      // Defaults that we expect may need to be overwritten in the future, should come before the props mixin
      // This ensures we only display 3 tokens by default, to keep the UI clean.
      // Users can show/hide any tokens after the first 3
      tokenLimit={3}
      {...props}
      filteringPlaceholder={t('propertyFilter.filteringPlaceholder')}
      hideOperations
      i18nStrings={props.propertyFilterStrings}
      query={props.query}
      customGroupsText={[
        {
          group: t('propertyFilter.mustMatch'),
          properties: t('propertyFilter.mustMatch'),
          values: t('propertyFilter.mustMatch'),
        },
      ]}
      freeTextFiltering={{
        operators: [':'],
      }}
    />
  );
}
