import { Header } from "@amzn/awsui-components-react";
import { Event, Shift } from "@amzn/red-velvet-api";
import { useSearchParams } from "react-router-dom";
import { DISPLAY_ATTRIBUTES_TYPE, ShiftInfo } from "../../shifts/shiftInfo";
import { SpaLink } from "../../SpaLink";
import { EditEventBenevityLink } from "../../shifts/actions";

interface ShiftHeaderProps {
  event: Event;
}

export function ShiftHeader(props: ShiftHeaderProps) {
  const { event } = props;
  const [ searchParams ] = useSearchParams();
  const selectedTimezone = searchParams.get('timezone');
  const urlFeatures = searchParams.get('features');
  const viewDetailsUrl = `/view-event?id=${event.eventId}${selectedTimezone ? '&timezone=' + selectedTimezone : ''}${urlFeatures ? '&features=' + urlFeatures : ''}`;
  return (
    <Header
      actions={<EditEventBenevityLink event={event}/>}>
      <SpaLink
        data-aci-analytics-name="trackAttendance_title"
        href={viewDetailsUrl}
      >
        <h2>{event.title}</h2>
      </SpaLink>
    </Header>
  );
}
interface ShiftCardProps {
  event: Event;
  shift: Shift;
  displayAttributes: DISPLAY_ATTRIBUTES_TYPE[];
}

export function ShiftCard({
    event,
    shift,
    displayAttributes,
  }: ShiftCardProps) {
  
    return (
      <ShiftInfo
        event={event}
        shift={shift}
        displayAttributes={displayAttributes}
      />
    );
  }