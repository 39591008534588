import { Header, SpaceBetween } from "@amzn/awsui-components-react";
import { Event, Shift, Attendance, Signup } from "@amzn/red-velvet-api";
import { useLatestAttendanceState } from "../../hooks/attendance/attendanceMutation";
import { ShiftActions } from "../shifts/actions";
import { DISPLAY_ATTRIBUTES_TYPE, useShiftInfo } from "../shifts/shiftInfo";

export function ShiftHeader({ shift }: { shift: Shift }) {
  return <Header variant="h3">{shift.name}</Header>;
}

interface ShiftBodyProps {
  event: Event;
  shift: Shift;
  attendance?: Attendance;
  displayAttributes: DISPLAY_ATTRIBUTES_TYPE[];
}
export function ShiftBody({ event, shift, attendance, displayAttributes }: ShiftBodyProps) {
  const latestAttendance = useLatestAttendanceState(shift.shiftId, attendance);

  const info = useShiftInfo({
    event,
    shift,
    attendance: latestAttendance,
    displayAttributes,
  });

  return (
    //THIS CLASS IS REQUIRED FOR INTEGRATION TESTING TO REGISTER THE EXISTANCE OF SHIFTS
    <div className="shift">
      <div className="shiftBody">
        <SpaceBetween direction="vertical" size="s">
          {info.map((item, idex) =>
            !item.type ? (
              <div key={idex}>
                <b>{item.label}:</b> {item.value}
              </div>
            ) : null
          )}
        </SpaceBetween>
      </div>
    </div>
  );
}

export type ShiftFooterProps = {
    event: Event;
    shift: Shift;
    signup?: Signup;
    attendance?: Attendance;
  };
  
  export function ShiftFooter({ event, shift, signup, attendance }: ShiftFooterProps) {
    return (
      <div className="shiftFooter">
        <ShiftActions event={event} shift={shift} signup={signup} attendance={attendance} />
      </div>
    );
  }
  