import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppLayout, ContentLayout, Header, Tabs, BreadcrumbGroup } from '@amzn/awsui-components-react';
import { ActivitySidebar } from './sideNav';

export function CoordinatorPage() {
  const { t } = useTranslation(['activity', 'translation']);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  const urlFeatures = searchParams.get('features');
  const coordinatorActivityUrl = `/coordinator-activity${urlFeatures ? '?features=' + urlFeatures : ''}`;

  return (
    <AppLayout
      footerSelector=".footer"
      navigationHide={false}
      navigation={<ActivitySidebar baseUrl={coordinatorActivityUrl} />}
      toolsHide={true}
      breadcrumbs={
        <div className="breadcrumbs">
          <BreadcrumbGroup
            items={[
              { text: t('breadcrumbs.home', { ns: 'translation' }), href: '/' },
              { text: t('breadcrumbs.coordinatorActivity'), href: '#' },
            ]}
            onFollow={(event) => {
              navigate(event.detail.href);
              event.preventDefault();
            }}
          />
        </div>
      }
      content={
        <div className="page-content">
          <ContentLayout
            header={
              <div className="page-content-header">
                <Header variant="h1">{t('pageTitle.coordinatorActivity')}</Header>
              </div>
            }
          >
            <Tabs
              tabs={[
                {
                  label: t('upcoming'),
                  id: 'upcoming',
                },
                {
                  label: t('past'),
                  id: 'past',
                },
              ]}
            />
          </ContentLayout>
          {t('incomingFeature')}
        </div>
      }
      contentType="cards"
    />
  );
}
