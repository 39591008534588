// GOLDEN PATH CODE
// This is a golden path example of a multi-page resource creation wizard
// Based on https://cloudscape.aws.dev/components/wizard/

import React from 'react';
import Wizard, { WizardProps } from '@amzn/awsui-components-react/polaris/wizard';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import { useWizardStrings } from '../../../hooks/localization/wizardStrings';
import { useUrlPage } from '../../../hooks/attendance/urlPage';
import { useSharedInputs } from '../../../hooks/attendance/formInputs';
import { useAttendeeSearchParams } from '../../../hooks/attendance/attendeeSearchParams';
import { publishKatalMetric } from '../../katalAnalytics';

const FIRST_PAGE = 0;

type WizardStepProps = {
  title: string;
  content: JSX.Element;
}[]

// GOLDEN PATH NOTE
// The AttendanceWizardProps only includes Partial<WizardProps> so that when the AttendanceWizard component is used,
// users do not have to pass in dummy steps that will be overwritten
export interface AttendanceWizardProps extends Partial<WizardProps> {
  steps: WizardStepProps;
  onCancel: NonCancelableEventHandler;
}

export function AttendanceWizard(props: AttendanceWizardProps) {
  // TODO - currently the wizard always starts at index 0 (ie event details), but we should skip this step if creating attendance
  // from a specific event (https://issues.amazon.com/issues/VOLUNTEERCENTER-277)
  const { page, setPage } = useUrlPage(FIRST_PAGE);
  const { resetEventInputs } = useSharedInputs();
  const { resetAttendees } = useAttendeeSearchParams();

  const namespaces = ['attendance', 'translation'];
  const wizardStrings = useWizardStrings('attendanceWizard', namespaces);

  return (
    <Wizard
      {...props}
      onCancel={(input) => {
        publishKatalMetric('attendance_cancelAttendance', '');
        props.onCancel(input);
      }}
      // GOLDEN PATH NOTE
      // When creating a Wizard, the active step should always be controlled, as it allows for more control over
      // step progression on a code level
      onNavigate={({ detail }) => {
        publishKatalMetric('attendance_wizardPage', `${detail.requestedStepIndex}`);
        setPage(detail.requestedStepIndex);
      }}
      activeStepIndex={page}
      // GOLDEN PATH NOTE
      // Since we are using a custom internationalization library instead of Cloudscape defaults,
      // these strings MUST be manually set using t(), but should follow the default patterns established in utils/localization
      // By passing in the t function from the component hook, we can customize the default namespace
      i18nStrings={wizardStrings}
      // GOLDEN PATH NOTE
      // Each step should have a title generated by i18n, and content that generated by a separate component imported from another file
      // The final step should be a summary step that displays all the information the user entered on previous steps, so that they
      // can verify everything is correct before the item is fully created
      steps={props.steps}
      // GOLDEN PATH NOTE
      // The submit button should ALWAYS do something (usually either redirecting users back to the page where the wizard was launched,
      // or to a list of items that includes the newly created item)
      onSubmit={() => {
        publishKatalMetric('attendance_clearAttendance', '');
        resetEventInputs();
        resetAttendees();
      }}
    />
  );
}
