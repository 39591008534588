import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  AppLayout,
  BreadcrumbGroup,
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { Shift } from '@amzn/red-velvet-api';
import {
  DISPLAY_COORDINATOR_INFO,
  DISPLAY_DATE_INFO,
  DISPLAY_LOCATION_INFO,
} from '../../shifts/shiftInfo';
import { loadAllShiftsForEvent } from '../../../hooks/betterEvents/load';
import { useQueryClient } from '@tanstack/react-query';
import { ShiftCard, ShiftHeader } from './shiftCard';
import { AttendanceWizard } from '../wizard/attendanceWizard';
import { AttendanceModal } from '../leaveModal';

import { SearchTable } from '../wizard/searchTable';
import { CoordinatorAttendeeTable } from './coordinatorAttendeeTable';
import { ParticipationWithFullname } from '../../../utils/attendance/peopleSearch';

import { useSingleBetterEventFromSearchParam } from '../../../hooks/betterEvents';

export interface CoordinatorAttendancePageProps {
  eventId: string;
  shiftId: string;
}

export function CoordinatorAttendancePage(props: CoordinatorAttendancePageProps) {
  const namespaces = ['attendance', 'translation'];
  const { t } = useTranslation(namespaces);
  const location = useLocation();

  const queryClient = useQueryClient();

  const [{ event, loading: eventLoading }] = useSingleBetterEventFromSearchParam();
  const [shift, setShift] = useState<Shift>();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);

  const { previousPagePath, previousPageName } = location.state || {};
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [navigateTo, setNavigateTo] = useState('#');

  const hasChanges = true;
  const onNavigateAway = (href: string) => {
    if (hasChanges) {
      setModalVisible(true);
      setNavigateTo(href);
    } else {
      navigate(href);
    }
  };
  const leave = () => navigate(navigateTo);

  const extractQueryResult = useCallback(
    <T,>(query: PromiseSettledResult<T>) => {
      if (query?.status === 'rejected' || (query?.status === 'fulfilled' && !query.value)) {
        console.log(
          `Failed to fetch event/shifts for eventId=[${props.eventId}] and shiftId=[${props.shiftId}]`
        );
      }

      return query.status === 'rejected'
        ? {
            reason: query.reason,
            value: null,
          }
        : {
            reason: null,
            value: query.value,
          };
    },
    [props.eventId, props.shiftId]
  );

  const loadEventAndShift = useCallback(async () => {
    setLoading(true);
    try {
      const [shiftsQuery] = await Promise.allSettled([
        loadAllShiftsForEvent(queryClient, props.eventId),
      ]);

      let shift = null;
      // check if we get a successful shifts response
      const shiftsResult = extractQueryResult(shiftsQuery);
      if (shiftsResult.value) {
        shift = shiftsResult.value.find((shift: Shift) => shift.shiftId === props.shiftId);
      }

      // check if the correct shift exists in the response
      if (shift) {
        setShift(shift);
      } else {
        setErrors((errors) => [
          ...errors,
          `Failed to get shift_id=[${props.shiftId}] ${shiftsResult.reason ?? ''}`,
        ]);
      }
    } finally {
      setLoading(false);
    }
  }, [extractQueryResult, props.eventId, props.shiftId, queryClient]);

  useEffect(() => {
    loadEventAndShift();
  }, [loadEventAndShift]);

  // TODO: To call getParticipationsByEvent API and display table load status
  const [participants, setParticipants] = useState<ParticipationWithFullname[]>([]);

  const buildPageLayout = (content: ReactElement) => {
    return (
      <AppLayout
        footerSelector=".footer"
        navigationHide={true}
        toolsHide={true}
        breadcrumbs={
          <div className="breadcrumbs">
            <BreadcrumbGroup
              items={[
                { text: t('breadcrumbs.home', { ns: 'translation' }), href: '/' },
                ...(previousPagePath && previousPageName
                  ? [
                      {
                        text: previousPageName,
                        href: previousPagePath,
                      },
                    ]
                  : []),
                { text: t('breadcrumbs.attendanceSheet'), href: '#' },
              ]}
              onFollow={(event) => {
                navigate(event.detail.href);
                event.preventDefault();
              }}
            />
          </div>
        }
        content={
          <div className="page-content">
            <ContentLayout
              header={
                <div className="page-content-header">
                  <Header variant="h1">{t('pageTitle.attendanceSheet')}</Header>
                </div>
              }
            >
              {content}
            </ContentLayout>
          </div>
        }
        contentType="wizard"
      />
    );
  };

  if (loading || eventLoading) {
    return buildPageLayout(
      <Container data-testid={'loading-spinner'}>
        <Spinner />
      </Container>
    );
  } else if (!event || !shift) {
    return buildPageLayout(
      <SpaceBetween direction="vertical" size="s">
        {errors.map((error) => (
          <Alert type="error">{error}</Alert>
        ))}
      </SpaceBetween>
    );
  } else {
    return buildPageLayout(
      <>
        {event && event.event ? (
          <Container>
            <ShiftHeader event={event.event} />
            <ShiftCard
              event={event.event}
              shift={shift}
              displayAttributes={[
                DISPLAY_DATE_INFO,
                DISPLAY_LOCATION_INFO,
                DISPLAY_COORDINATOR_INFO,
              ]}
            />
          </Container>
        ) : null}
        <AttendanceWizard
          steps={[
            {
              title: t('attendanceWizard.coordinatorTracking.stepTitles.trackAttendance'),
              content: (
                <SpaceBetween direction="vertical" size="l">
                   <CoordinatorAttendeeTable
                     participants={participants}
                     setParticipants={setParticipants}
                   />
                   <SearchTable participants={participants} setParticipants={setParticipants} />
                 </SpaceBetween>
              ),
            },
            {
              title: t('attendanceWizard.coordinatorTracking.stepTitles.trackTimeAndSubmit'),
              content: (
                <CoordinatorAttendeeTable
                  participants={participants}
                  setParticipants={setParticipants}
                />
              ),
            },
          ]}
          onNavigate={({ detail }) => {
            if (detail.reason === 'next' && detail.requestedStepIndex === 1) {
              setParticipants((curParticipants) =>
                curParticipants.filter((p) => p.attendanceStatus === 'ATTENDED')
              );
            }
          }}
          onCancel={() => onNavigateAway('/my-activity')}
        />
        <AttendanceModal
          hasChanges={false}
          onConfirm={leave}
          visible={modalVisible}
          onDismiss={() => setModalVisible(false)}
        />
      </>
    );
  }
}
