// Components
import {
  AppLayout,
  BreadcrumbGroup,
  ContentLayout,
  Header,
  Link,
  SpaceBetween,
  Tabs,
} from '@amzn/awsui-components-react';
import { SignupList } from './signupsList';
import { ActivitySidebar } from './sideNav';
// Hooks
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { featureIsEnabled, useFeatures } from '../../hooks/features';
// Utils
import { externalLinks } from '../../data/externalLinks';
import {
  DISPLAY_ATTENDANCE_INFO,
  DISPLAY_DATE_INFO,
  DISPLAY_LOCATION_INFO,
} from '../shifts/shiftInfo';

export function ActivityPage() {
  const { t } = useTranslation(['activity', 'translation']);
  const features = useFeatures();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const coordinatorActivityEnabled = featureIsEnabled(
    'CoordinatorActivity',
    features,
    searchParams
  );
  const myActivityEnabled = featureIsEnabled('MyActivity', features, searchParams);

  const urlFeatures = searchParams.get('features');
  const volunteerActivityUrl = `/my-activity${urlFeatures ? '?features=' + urlFeatures : ''}`;

  return (
    <AppLayout
      footerSelector=".footer"
      navigationHide={coordinatorActivityEnabled ? false : true}
      navigation={coordinatorActivityEnabled && <ActivitySidebar baseUrl={volunteerActivityUrl} />}
      toolsHide={true}
      breadcrumbs={
        <div className="breadcrumbs">
          <BreadcrumbGroup
            items={[
              { text: t('breadcrumbs.home', { ns: 'translation' }), href: '/' },
              {
                text: t(
                  coordinatorActivityEnabled
                    ? 'breadcrumbs.volunteerActivity'
                    : 'breadcrumbs.activity'
                ),
                href: '#/',
              },
            ]}
            onFollow={(event) => {
              navigate(event.detail.href);
              event.preventDefault();
            }}
          />
        </div>
      }
      content={
        myActivityEnabled ? (
          <div className="page-content">
            <ContentLayout
              header={
                <div className="page-content-header">
                  {coordinatorActivityEnabled ? (
                    <Header
                      variant="h1"
                      actions={
                        <Link
                          data-aci-analytics-name="myActivity_reportTime"
                          href={externalLinks.support.trackTime}
                          external
                        >
                          {t('trackTime')}
                        </Link>
                      }
                    >
                      {t('pageTitle.volunteerActivity')}
                    </Header>
                  ) : (
                    <Header variant="h1">{t('pageTitle.myActivity')}</Header>
                  )}
                </div>
              }
            >
              <SpaceBetween direction="vertical" size="xxl">
                {!coordinatorActivityEnabled && (
                  <Header
                    actions={
                      <Link
                        data-aci-analytics-name="myActivity_reportTime"
                        href={externalLinks.support.trackTime}
                        external
                      >
                        {t('trackTime')}
                      </Link>
                    }
                  >
                    {t('pageTitle.volunteerActivity')}
                  </Header>
                )}
                <Tabs
                  tabs={[
                    {
                      label: t('upcoming'),
                      id: 'upcoming',
                      content: (
                        <SignupList
                          startDate={new Date(Date.now())}
                          sort="oldest-first"
                          displayAttributes={[DISPLAY_DATE_INFO, DISPLAY_LOCATION_INFO]}
                        />
                      ),
                    },
                    {
                      label: t('past'),
                      id: 'past',
                      content: (
                        <SignupList
                          endDate={new Date(Date.now())}
                          sort="newest-first"
                          displayAttributes={[
                            DISPLAY_DATE_INFO,
                            DISPLAY_LOCATION_INFO,
                            DISPLAY_ATTENDANCE_INFO,
                          ]}
                        />
                      ),
                    },
                  ]}
                />
              </SpaceBetween>
            </ContentLayout>{' '}
          </div>
        ) : (
          t('activityDisabled')
        )
      }
      contentType="cards"
    />
  );
}
