import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import { usePropertyFilterStrings } from '../../../hooks/localization/propertyFilterStrings';
import { FilterProperties, TablePropertyFilter } from '../wizard/tablePropertyFilter';

export type PeoplePropertyFilterProps = {
  query: PropertyFilterProps.Query;
  onChange: NonNullable<PropertyFilterProps['onChange']>;
};

const csvAttendanceProperties = [
  { name: 'person', operators: [':'] },
  { name: 'manager' },
  { name: 'building' },
  { name: 'country' },
  {
    name: 'level',
    values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12', '99'],
  },
  { name: 'alias', group: 'mustMatch' },
];

export function PeoplePropertyFilter(props: PeoplePropertyFilterProps) {
  const namespaces = ['attendance', 'translation'];
  const propertyFilterStrings = usePropertyFilterStrings('propertyFilter', namespaces);

  return (
    <TablePropertyFilter
      {...props}
      query={props.query}
      propertyFilterStrings={propertyFilterStrings}
      // GOLDEN PATH NOTE
      // We do pre-processing of the tokens here, so that any values that the component generates are fully cleaned
      // and can be used as-is in other hooks/components
      onChange={({ detail }) => {
        const flattenedTokens = detail.tokens.flatMap((token) => {
          // If we have previously processed a complex token, or if the user has typed in a simple token, we can just use it as is
          if (!token.value.includes(',') && token.value.trim() !== '') return token;

          // If the user enters a complex token we need to post-process into seperate tokens
          // A complex token can be a search term with commas, which indicates it is actually multiple search terms
          // or it can be a blank token (ie only whitespace), or a combination of the two
          return token.value
            .split(',')
            .filter((tokenValue: string) => tokenValue.trim() !== '')
            .map((tokenValue: string) => ({
              value: tokenValue.trim(),
              propertyKey: token.propertyKey,
              operator: token.operator,
            }));
        });
        props.onChange(
          new CustomEvent('onPeoplePropertyFilterChange', {
            detail: {
              tokens: flattenedTokens,
              operation: 'or',
            },
          })
        );
      }}
      filteringOptions={csvAttendanceProperties.flatMap((property) =>
        (property.values || []).map((value) => ({ propertyKey: property.name, value }))
      )}
      filteringProperties={FilterProperties(csvAttendanceProperties)}
    />
  );
}
