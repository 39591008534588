import { Select } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { ParticipationStatus, ParticipationWithFullname } from '../../../utils/attendance/peopleSearch';

interface AttendanceSelectProps {
  participant: ParticipationWithFullname;
  currentValue: string;
  onAttendanceChange: (status: ParticipationStatus) => void;
}

export const AttendanceSelect = (props: AttendanceSelectProps) => {
  const { participant, currentValue, onAttendanceChange } = props;
  const { t } = useTranslation(['attendance', 'translation']);

  const ATTENDANCE_OPTIONS = [
    { label: t('coordinatorAttendeeTable.coordinatorTracking.pending'), value: 'PENDING' },
    { label: t('coordinatorAttendeeTable.coordinatorTracking.setAttended'), value: 'ATTENDED' },
    { label: t('coordinatorAttendeeTable.coordinatorTracking.setDidNotAttend'), value: 'DID_NOT_ATTEND' },
  ] as const;

  return (
    <Select
      ariaLabel="Select attendance"
      autoFocus={true}
      expandToViewport={true}
      options={participant.isSignedUp ? ATTENDANCE_OPTIONS : ATTENDANCE_OPTIONS.slice(1)} // For non-signup attendance, only show Attend/Did not attend
      selectedOption={ATTENDANCE_OPTIONS.find((option) => option.value === currentValue) ?? null}
      onChange={(event) => {
        onAttendanceChange(event.detail.selectedOption.value as ParticipationStatus);
      }}
    />
  );
};