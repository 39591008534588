/**
 * Serves either CSV tracking or Coordinator tracking depending on feature flag
 */

import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFeatures, featureIsEnabled } from './../../hooks/features';
import { CoordinatorAttendancePage } from './coordinator/coordinatorAttendance';

import { CsvAttendancePage } from './csvAttendance/csvAttendance';
import { getValueFromSearchParam } from '../../utils/url';

export function AttendancePage() {
  const features = useFeatures();
  const [searchParams] = useSearchParams();

  const eventId = useMemo(
    () => getValueFromSearchParam(searchParams, 'eventId', ''),
    [searchParams]
  );
  const shiftId = useMemo(
    () => getValueFromSearchParam(searchParams, 'shiftId', ''),
    [searchParams]
  );

  if (featureIsEnabled('CoordinatorTrack', features, searchParams) && eventId && shiftId) {
    // TODO cce-4563: Remove feature flag
    return <CoordinatorAttendancePage eventId={eventId} shiftId={shiftId} />;
  }

  return <CsvAttendancePage />;
}
