import React from 'react';
import TopNavigation, {
  TopNavigationProps,
} from '@amzn/awsui-components-react/polaris/top-navigation';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '../hooks/user';
import { useFeatures, featureIsEnabled } from '../hooks/features';

import { externalLinks } from '../data/externalLinks';
import { publishKatalMetric } from './katalAnalytics';
import { useSpaWrapper } from './SpaLink';

export function TopNav() {
  const spaWrapper = useSpaWrapper();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { userId } = useUser();
  const features = useFeatures();

  const defaultUtilites: TopNavigationProps.Utility[] = [
    {
      type: 'button',
      text: t('topnav.search'),
      variant: 'link',
      href: '/',
      onFollow: spaWrapper({
        onFollow: () => {
          publishKatalMetric('Click', 'nav_search');
        },
      }),
    },
    {
      type: 'menu-dropdown',
      iconName: 'support',
      text: t('topnav.help'),
      onItemFollow: ({ detail }) => {
        publishKatalMetric('Click', 'nav_' + detail.id);
      },
      items: [
        {
          text: t('topnav.resources'),
          items: [
            {
              id: 'aciWiki',
              text: t('footer.aciWiki'),
              href: externalLinks.support.aciWiki,
              external: true,
            },
            {
              id: 'planningPlaybooks',
              text: t('topnav.planningPlaybooks'),
              href: externalLinks.support.planningPlaybooks,
              external: true,
            },
            {
              id: 'faqs',
              text: t('topnav.faqs'),
              href: externalLinks.support.faqs,
              external: true,
            },
          ],
        },
        {
          text: t('topnav.support'),
          items: [
            {
              id: 'question',
              text: t('topnav.question'),
              href: externalLinks.support.question,
              external: true,
            },
            {
              id: 'ticket',
              text: t('topnav.ticket'),
              href: externalLinks.support.ticket,
              external: true,
            },
          ],
        },
      ],
    },
    {
      type: 'button',
      text: userId + '@',
      variant: 'link',
    },
  ];

  if (featureIsEnabled('Hiring', features, searchParams)) {
    const hiringOption: TopNavigationProps.Utility = {
      type: 'button',
      text: t('topnav.hiring'),
      variant: 'link',
      href: externalLinks.support.hiring,
      target: '_blank',
      iconName: 'external',
      //there is no good way to css select a specific button in the title of topnav
      //unless you use the aria label. And you don't think to hard about the meaning of the word "good"
      //https://issues.amazon.com/issues/cce-4026
      ariaLabel: t('topnav.hiring'),
      onFollow: (event) => {
        publishKatalMetric('Click', 'nav_wereHiring');
      },
    };
    defaultUtilites.unshift(hiringOption);
  }

  if (featureIsEnabled('MyActivity', features, searchParams)) {
    const myActivity: TopNavigationProps.Utility = {
      type: 'button',
      text: t('topnav.myActivity'),
      variant: 'link',
      href: '/my-activity',
      ariaLabel: t('topnav.myActivity'),
      onFollow: (event) => {
        publishKatalMetric('Click', 'nav_myActivity');
      },
    };
    // Splice in to be between after search whether hiring is enabled or not
    const searchIndex = defaultUtilites.findIndex((navItem) => navItem.text === t('topnav.search'));
    defaultUtilites.splice(searchIndex + 1, 0, myActivity);
  }

  return (
      <TopNavigation
        identity={{
          href: '/',
          //annoyingly something in cloudscape fubars the onFollow event
          //and removes the href. TLDR: you can't use spaWrapper because it doesn't know where
          //to navigate because there is no event.detail.href
          onFollow: spaWrapper({ url: '/' }),
          logo: {
            src: '/red-velvet-logo.svg',
            alt: t('programName'),
          },
        }}
        utilities={defaultUtilites}
      />
  );
}
