import { Event, Shift, Signup, Location, Attendance, AttendanceStatus } from '@amzn/red-velvet-api';

export const TEST_SKILLS: string[] = ['Krusty', 'Craft'];
export const TEST_EMPTY_SKILLS: string[] = [];
export const TEST_CITY = 'Toronto';
export const TEST_PROVINCE = 'ON';

export const TEST_LOCATION: Location = {
  name: 'CN Tower',
  notes: 'Test location notes',
  latitude: 36.199193,
  longitude: -86.794231,
  street: '290 Bremner Blvd,',
  city: 'Toronto',
  province: 'ON',
  country: 'Canada',
  postal_code: '37228',
};

export const TEST_EVENT: Event = {
  eventId: '1',
  title: 'TEST_EVENT_TITLE',
  description: 'TEST EVENT DESCRIPTION',
  summary: 'TEST EVENT SUMMARY',
  cause: 'TEST CAUSE',
  contacts: [
    {
      name: 'ken',
      email: 'kenough@amazon.com',
      phone: '555-555-5555',
    },
  ],
  location: {},
  rvSignupSupported: false,
  skills: TEST_SKILLS,
};

export const TEST_EVENT_PHYSICAL_LOCATION: Event = {
  eventId: '1',
  title: 'TEST_EVENT_TITLE',
  description: 'TEST EVENT DESCRIPTION',
  summary: 'TEST EVENT SUMMARY',
  cause: 'TEST CAUSE',
  contacts: [
    {
      name: 'ken',
      email: 'kenough@amazon.com',
      phone: '555-555-5555',
    },
  ],
  location: TEST_LOCATION,
  rvSignupSupported: false,
  skills: TEST_SKILLS,
};

export const TEST_EVENT_EMPTY_SKILLS: Event = {
  eventId: '1',
  title: 'TEST_EVENT_TITLE',
  description: 'TEST EVENT DESCRIPTION',
  location: {},
  rvSignupSupported: false,
  skills: TEST_EMPTY_SKILLS,
};

export const TEST_SHIFT: Shift = {
  shiftId: 'TEST_SHIFT_ID',
  name: 'SHIFT_NAME',
  eventId: '1',
  startDateTime: new Date('2023-06-30T02:00:00.000+0000'),
  endDateTime: new Date('2023-06-30T07:00:00.000+0000'),
  maxSignup: 10,
  signupsRemaining: 3,
  signupsOpen: true,
  complete: false,
};

export const TEST_FUTURE_SHIFT: Shift = {
  shiftId: "TEST_SHIFT_ID",
  name: "SHIFT_NAME",
  eventId: "1",
  startDateTime: new Date("3024-06-30T02:00:00.000+0000"),
  endDateTime: new Date("3024-06-30T07:00:00.000+0000"),
  maxSignup: 10,
  signupsRemaining: 3,
  signupsOpen: true,
  complete: false,
};

export const TEST_SIGNUP: Signup = {
  signupId: 'TEST_SIGNUP_ID',
  shiftId: 'TEST_SHIFT_ID',
  userId: 'TEST_USER',
  eventId: '1',
  startDateTime: new Date('2023-06-30T02:00:00.000+0000'),
};

export const TEST_ATTENDANCE: Attendance = {
  attendanceId: 'TEST_ATTENDANCE_ID',
  shiftId: 'TEST_SHIFT_ID',
  alias: 'TEST_USER',
  userId: 'TEST_USER_ID',
  eventId: '1',
  minutes: 10,
  attendanceStatus: AttendanceStatus.ATTENDED,
  reportedTime: new Date('2023-06-30T02:00:00.000+0000'),
  updatedTime: new Date('2023-06-30T02:00:00.000+0000'),
};

export const TEST_ATTENDANCE_DID_NOT_ATTEND: Attendance = {
  attendanceId: 'TEST_ATTENDANCE_ID',
  shiftId: 'TEST_SHIFT_ID',
  alias: 'TEST_USER',
  userId: 'TEST_USER_ID',
  eventId: '1',
  minutes: 0,
  attendanceStatus: AttendanceStatus.DID_NOT_ATTEND,
  reportedTime: new Date('2023-06-30T02:00:00.000+0000'),
  updatedTime: new Date('2023-06-30T02:00:00.000+0000'),
};
