import * as React from 'react';
import { SearchTable } from '../wizard/searchTable';
import { AttendeeTable } from './attendeeTable';
import { SpaceBetween } from '@amzn/awsui-components-react';

export function AttendeeTracker() {
  return (
    <SpaceBetween direction="vertical" size="l">
      <>
        <SearchTable participants={[]} setParticipants={() => []} />
        <AttendeeTable />
      </>
    </SpaceBetween>
  );
}
