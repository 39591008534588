import { Button, SpaceBetween } from "@amzn/awsui-components-react";
import { useTranslation } from "react-i18next";
import { ParticipationStatus } from "../../../utils/attendance/peopleSearch";

export interface HeaderActionsProps {
  onBulkUpdate: (status: ParticipationStatus) => void;
}

export const HeaderActions = (props: HeaderActionsProps) => {
  const { t } = useTranslation(['attendance', 'translation']);
  const { onBulkUpdate } = props;

  return (
    <SpaceBetween direction="horizontal" size="s">
      <Button variant="normal" onClick={() => onBulkUpdate('DID_NOT_ATTEND')}>
        {t('attendeeTable.coordinatorTracking.setDidNotAttend')}
      </Button>
      <Button variant="primary" onClick={() => onBulkUpdate('ATTENDED')}>
        {t('attendeeTable.coordinatorTracking.setAttended')}
      </Button>
    </SpaceBetween>
  );
};