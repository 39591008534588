// GOLDEN PATH CODE
// This is a Carousel-enabled card component.
// It can be used to display an arbitrarily large list of items.  Only a single row
// of items is displayed, and users can use pagination to see more items in the list.

// Components
import { Alert, Cards, CardsProps, Header, Pagination, PaginationProps } from '@amzn/awsui-components-react/polaris';
import {
  DISPLAY_ATTENDANCE_INFO,
  DISPLAY_ATTRIBUTES_TYPE,
  DISPLAY_DATE_INFO,
  DISPLAY_SIGNUPS_REMAINING_INFO_WITHOUT_CONDITION,
  DISPLAY_SIGNUPS_REMAINING_INFO_WITH_CONDITION,
} from '../shifts/shiftInfo';
// Hooks
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useFeatures } from '../../hooks/features';
import { useCarouselDisplayCount } from '../../hooks/shifts/shiftDisplay';
import { useUser } from '../../hooks/user';
// Types
import {
  Event,
  GetAttendancesByQueryParamCommandOutput,
  GetShiftsByEventCommandOutput,
  GetSignupsByQueryParamCommandOutput,
  Shift,
} from '@amzn/red-velvet-api';
// Utils
import { publishKatalMetric } from '../katalAnalytics';
import { filterBetterShifts } from '../../utils/filterUtils';
import { useAllPagesRedVelvetQuery } from '../../hooks/allPagesRedVelvetQuery';
import { useSignupAlerts } from '../../hooks/shifts/shiftSignup';
import { ShiftBody, ShiftFooter, ShiftHeader } from './shiftCard';

// SHIFT_WIDTH is used when determining how many shifts to display in the paginated carousel
// We recently upgraded it from 300 to 400 so the Did Not Attend & Track Time buttons can fit in the card
export const SHIFT_WIDTH_PIXELS = 400;

type ShiftCarouselProps = {
  carouselTitle?: string;
  paginationType?: 'top' | 'bottom' | 'both';
  event: Event;
  displayAttributes?: DISPLAY_ATTRIBUTES_TYPE[];
} & Partial<CardsProps>;

function ShiftPagination(props: PaginationProps) {
  const { t } = useTranslation();
  return (
    <Pagination
      {...props}
      onChange={(event) => {
        publishKatalMetric('shiftPage', event.detail.currentPageIndex.toString());
        if (props.onChange) props.onChange(event);
      }}
      ariaLabels={{
        nextPageLabel: t('eventList.topPaginationControl.next'),
        previousPageLabel: t('eventList.topPaginationControl.previous'),
        paginationLabel: t('eventList.topPaginationControl.label'),
      }}
    />
  );
}

export function ShiftCarousel(props: ShiftCarouselProps) {
  const { t } = useTranslation();
  const shiftsPerPage = useCarouselDisplayCount(SHIFT_WIDTH_PIXELS);
  const {
    event,
    displayAttributes = [DISPLAY_DATE_INFO, DISPLAY_SIGNUPS_REMAINING_INFO_WITHOUT_CONDITION],
  } = props;
  const user = useUser();

  // GOLDEN PATH NOTE
  // Whenever possible, components should be responsible for fetching their own data
  // In this case, the carousel needs shifts and signups, so we need to query both
  const shiftsQuery = useAllPagesRedVelvetQuery({
    redVelvetQueryKey: ['getShiftsByEvent', { eventId: event.eventId }],
    converter: (output: GetShiftsByEventCommandOutput) => ({
      items: output.shifts || [],
      next: output.page,
    }),
  });
  const signupQuery = useAllPagesRedVelvetQuery({
    redVelvetQueryKey: ['getSignupsByQueryParam', { eventId: event.eventId, alias: user.userId }],
    converter: (output: GetSignupsByQueryParamCommandOutput) => ({
      items: output.signups || [],
      next: output.page,
    }),
  });
  const attendanceQuery = useAllPagesRedVelvetQuery({
    redVelvetQueryKey: [
      'getAttendancesByQueryParam',
      { eventId: event.eventId, userAlias: user.userId },
    ],
    converter: (output: GetAttendancesByQueryParamCommandOutput) => ({
      items: output.attendances || [],
      next: output.page,
    }),
  });

  const [searchParams] = useSearchParams();
  const features = useFeatures();

  const alertInfo = useSignupAlerts(event.eventId);

  // GOLDEN PATH NOTE
  // The carousel component should be the source of truth for what children are displayed
  // so that parents can simply pass in the data and get a consistent result
  // every time they use the carousel
  const filteredShifts = filterBetterShifts(
    shiftsQuery.data || [],
    signupQuery.data || [],
    searchParams,
    features
  );

  // GOLDEN PATH NOTE
  // The carousel should manage any alerts that need to display for the
  // whole carousel
  const alertContent =
    alertInfo.alert !== undefined ? (
      <Alert
        dismissible
        statusIconAriaLabel={t('eventDisplay.' + alertInfo.alert.type + 'Title')}
        onDismiss={alertInfo.onDismiss}
        type={alertInfo.alert.type}
      >
        <b>{t('eventDisplay.' + alertInfo.alert.type + 'Title')}</b>
        <div>{alertInfo.alert.message}</div>
      </Alert>
    ) : null;

  const { items, paginationProps } = useCollection(filteredShifts, {
    filtering: {},
    pagination: { pageSize: props.cardsPerRow ? props.cardsPerRow[0].cards : shiftsPerPage },
    sorting: {},
    selection: {},
  });

  if (!shiftsQuery.isFetched || !signupQuery.isFetched || !attendanceQuery.isFetched) return null;
  return (
    <>
      {props.carouselTitle || props.paginationType === 'top' || props.paginationType === 'both' ? (
        // GOLDEN PATH NOTE
        // The Headers are used to ensure that pagination is easily accessible and displays on the right
        // side of the page. However, it should only display if either pagination or title are included
        <Header
          variant="h2"
          actions={
            props.pagination === 'top' || props.pagination === 'both' ? (
              <ShiftPagination {...paginationProps} />
            ) : null
          }
        >
          {props.carouselTitle}
        </Header>
      ) : null}

      {/* This test id is ONLY used to check if the cards are loaded in the snapshot integ tests */}
      {/* because the snapshot tests override Cloudscape rendering so there is no indicator if */}
      {/* items are loaded */}
      <div data-testid="shift-carousel" className="shiftCard">
        <Cards
          // GOLDEN PATH NOTE
          // Use the window observer to determine how many items to display in the carousel by default
          // but allow the parent component to override this for design reasons
          cardsPerRow={props.cardsPerRow || [{ cards: shiftsPerPage }]}
          {...props}
          cardDefinition={{
            header: (shift: Shift) => <ShiftHeader shift={shift} />,
            sections: [
              {
                content: (shift: Shift) => {
                  // Filter displayAttributes based on conditions before passing to ShiftBody
                  const updatedDisplayAttributes = displayAttributes.filter((attribute) => {
                    if (
                      attribute === DISPLAY_SIGNUPS_REMAINING_INFO_WITH_CONDITION &&
                      shift.complete
                    ) {
                      // Remove DISPLAY_SIGNUPS_REMAINING_INFO_WITH_CONDITION if shift is complete
                      return false;
                    }
                    if (attribute === DISPLAY_ATTENDANCE_INFO && !shift.complete) {
                      // Remove DISPLAY_ATTENDANCE_INFO if shift is incomplete
                      return false;
                    }
                    // Keep all other attributes
                    return true;
                  });
                  return (
                    <ShiftBody
                      event={event}
                      shift={shift}
                      attendance={attendanceQuery.data?.find((a) => a.shiftId === shift.shiftId)}
                      displayAttributes={updatedDisplayAttributes}
                    />
                  );
                },
              },
              {
                content: (shift: Shift) => (
                  <ShiftFooter
                    event={event}
                    shift={shift}
                    signup={signupQuery.data?.find((s) => s.shiftId === shift.shiftId)}
                    attendance={attendanceQuery.data?.find((a) => a.shiftId === shift.shiftId)}
                  />
                ),
              },
            ],
          }}
          items={items}
        />
      </div>
      {alertContent}
      {props.paginationType === 'bottom' || props.paginationType === 'both' ? (
        <Header variant="h2" actions={<ShiftPagination {...paginationProps} />} />
      ) : null}
    </>
  );
}
