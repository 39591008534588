import React from 'react';
import { SideNavigation } from "@amzn/awsui-components-react";
import { externalLinks } from "../../data/externalLinks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

interface ActivitySidebarProps {
  baseUrl: string;
}

export function ActivitySidebar(props: ActivitySidebarProps) {
    const { t } = useTranslation(['activity', 'translation']);
    
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const urlFeatures = searchParams.get('features');
    const volunteerActivityUrl = `/my-activity${urlFeatures ? '?features=' + urlFeatures : ''}`;
    const coordinatorActivityUrl = `/coordinator-activity${urlFeatures ? '?features=' + urlFeatures : ''}`;

    const [activeHref, setActiveHref] = useState(props.baseUrl);
    
    return <SideNavigation
      activeHref={activeHref}
      header={{
        href: '#/',
        text: t('sideNavigation.title'),
      }}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          setActiveHref(event.detail.href);
          navigate(event.detail.href);
        }
      } }
      items={[
        {
          type: 'link',
          text: t('sideNavigation.volunteerActivity'),
          href: volunteerActivityUrl,
        },
        {
          type: 'link',
          text: t('sideNavigation.coordinatorActivity'),
          href: coordinatorActivityUrl,
        },
        { type: 'divider' },
        {
          type: 'link',
          text: t('sideNavigation.wikiPlanEvent'),
          href: externalLinks.myActivity.wiki.planEvent,
          external: true,
        },
        {
          type: 'link',
          text: t('sideNavigation.wikiManageEvent'),
          href: externalLinks.myActivity.wiki.manageEvent,
          external: true,
        },
      ]} />;
  }