// Components
import { Alert, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { ShiftActions } from '../shifts/actions';
import { DISPLAY_ATTRIBUTES_TYPE, ShiftInfo } from '../shifts/shiftInfo';
import { SpaLink } from '../SpaLink';
// Hooks
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSignupAlerts } from '../../hooks/shifts/shiftSignup';
// Types
import { DetailedSignup } from '@amzn/red-velvet-api';
import { useLatestAttendanceState } from '../../hooks/attendance/attendanceMutation';

export function SignupHeader({ event, shift, signup, attendance }: DetailedSignup) {
  const [searchParams] = useSearchParams();
  const selectedTimezone = searchParams.get('timezone');
  const urlFeatures = searchParams.get('features');
  const viewDetailsUrl = `/view-event?id=${event.eventId}${selectedTimezone ? '&timezone=' + selectedTimezone : ''}${urlFeatures ? '&features=' + urlFeatures : ''}`;

  // Note: this is a non-standard pattern; Cloudscape defaults to a hamburger menu in the corner of
  // the card for in-context action, but the design called for the buttons in order to enable one click actions
  return (
    <Header
      actions={<ShiftActions event={event} shift={shift} signup={signup} attendance={attendance} />}
    >
      <SpaLink
        data-aci-analytics-name="searchResult_title"
        className={'card-title'}
        href={viewDetailsUrl}
      >
        <h2>{event.title}</h2>
      </SpaLink>
    </Header>
  );
}
interface SignupCardProps extends DetailedSignup {
  displayAttributes: DISPLAY_ATTRIBUTES_TYPE[];
}
export function SignupCard({
  event,
  shift,
  signup,
  attendance,
  displayAttributes,
}: SignupCardProps) {
  const { t } = useTranslation(['activity', 'translation']);
  const latestAttendance = useLatestAttendanceState(shift.shiftId, attendance);

  // Note: The usage of alert within a card is non-standard for Cloudscape, but enables greater accessibility
  const alertInfo = useSignupAlerts(event.eventId, shift.shiftId);
  const alertContent =
    alertInfo.alert !== undefined ? (
      <Alert
        dismissible
        statusIconAriaLabel={t('eventDisplay.' + alertInfo.alert.type + 'Title')}
        onDismiss={alertInfo.onDismiss}
        type={alertInfo.alert.type}
        header={t('eventDisplay.' + alertInfo.alert.type + 'Title')}
      >
        {alertInfo.alert.message}
      </Alert>
    ) : null;
  return (
    <>
      <SpaceBetween direction="vertical" size="s">
        <ShiftInfo
          event={event}
          shift={shift}
          attendance={latestAttendance}
          displayAttributes={displayAttributes}
        />
        {alertContent}
      </SpaceBetween>
    </>
  );
}
